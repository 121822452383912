export const FLOW_PIPE = {
  LIST: '|| ,',
  ITEM: '::',
};

export const VAT = 0.2;

export const SEO_TITLE =
  'Expert Gas Engineers in London | Heating Engineer London | Qeeper';
export const SEO_DESCRIPTION =
  'Expert Gas Engineers in London offering emergency services, advance plumbing, boiler repair, installation, and more. Trust our team for all your heating needs.';
export const SEO_KEYWORDS =
  'Expert Gas Engineers London, Emergency Gas Engineers London, Advance Plumbing Services London, Heating & Gas Safe Engineer London, Boiler Repair Services London, Heating & Gas Installation London, Plumber London, Gas Engineer London, Heating Engineer London, Boiler Repair London, Boiler Replacement London, Electrician London, EICR London, Landlord Certificates London, Gas Safety Check London';
